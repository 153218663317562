import React, { useCallback } from 'react';
import { InitializationErrorsCode } from '../../../Errors/InitializationErrors';
import { storage } from '../../../Services/storage';
import styles from './ErrorPage.module.css';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { motion } from 'framer-motion';
import { Button } from '../../Components/BaseComponents/Button';
import { ErrorIcon } from '../PaymentPage/icons';
const initializationErrorText = {
    [InitializationErrorsCode.WrongApiKey]: {
        title: msg `Invalid API key`,
        description: msg `Please check your widget settings, you might be using the wrong API key.`,
    },
    [InitializationErrorsCode.NoOneCurrencyPair]: {
        title: msg `We cannot find any available currency pair`,
        description: msg `Check the widget settings, you may be trying to open a pair that doesn't exist.`,
    },
    [InitializationErrorsCode.UnknowingError]: {
        title: msg `Something went wrong`,
        description: msg `Try reloading the widget or contact support.`,
    },
    [InitializationErrorsCode.BlockedUser]: {
        title: msg `Transactions are limited`,
        description: msg `Unfortunately, we cannot process your transaction in accordance with the internal policy of the company.`,
    },
    [InitializationErrorsCode.BlockedGeo]: {
        title: msg `Coming soon to your region!`,
        description: msg `As soon as we are ready to work in your country, we will definitely let you know.`,
        img: './asset/errors/world.svg',
    },
};
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1,
        },
    },
};
const itemVariants = {
    hidden: {
        y: 8,
        opacity: 0,
        filter: 'blur(4px)',
    },
    visible: {
        y: 0,
        opacity: 1,
        filter: 'blur(0px)',
        transition: {
            duration: 0.25,
        },
    },
};
export default function ErrorPage({ errorCode = InitializationErrorsCode.UnknowingError, showReloadButton = false, }) {
    const { _ } = useLingui();
    const { title, description, img } = initializationErrorText[errorCode];
    const reloadApp = useCallback(() => {
        storage.clearAll();
        window.location.reload();
    }, []);
    return (<motion.div className={styles.container} variants={containerVariants} initial="hidden" animate="visible">
      <motion.div className={styles.content}>
        {img ? (<motion.img className={styles.img} variants={itemVariants} src={img}/>) : (<ErrorIcon />)}

        <motion.div className={styles.info}>
          <motion.div className={styles.title} variants={itemVariants}>
            {_(title)}
          </motion.div>
          <motion.div className={styles.description} variants={itemVariants}>
            {_(description)}
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div className={styles.footer}>
        {showReloadButton && (<Button label="Reload" dataTestId="primary-button" onClick={reloadApp}/>)}
      </motion.div>
    </motion.div>);
}
